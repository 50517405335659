import React from "react";
import '../../styles/semantic.min.css';
import LayoutContainer from "../../container/LayoutContainer";
import SEO from "../../components/seo/Seo";
import MainNavigation from "../../components/navigation/MainNavigation";
import Footer from "../../components/footer/Footer";
import Transparency from "../../components/foundation/transparency";
import PageHeader from "../../components/utils/PageHeader";
import {Container, Grid} from "semantic-ui-react";
import MobileTabletContainer from "../../container/MobileTabletContainer";
import styles from "../../components/foundation/foundation.module.css";
import DesktopContainer from "../../container/DesktopContainer";
import layout from "../../templates/layout.module.css";

export default () => <LayoutContainer>
    <SEO title={'Transparenz'}/>

        <MobileTabletContainer className={styles.mobile}>
            <MainNavigation/>
            <Container fluid>
                <PageHeader title={'Transparenz'}/>
                    <Transparency/>
            </Container>
            <Footer/>
        </MobileTabletContainer>
    <DesktopContainer className={styles.desktop}>
        <div className={layout.pageWrapper}>
        <MainNavigation/>
        <Container fluid>
            <PageHeader title={'Transparenz'}/>
            <Grid centered columns={2}>
                <Grid.Column width={11}>
                    <Transparency/>
                </Grid.Column>
                <Grid.Column width={4}/>
            </Grid>
        </Container>
        <Footer/>
        </div>
    </DesktopContainer>


</LayoutContainer>