import React from "react";
import {Container, Header, Menu, Rail, Segment} from "semantic-ui-react";
import {Link} from "@reach/router";
import styles from "./foundation.module.css";
import cx from 'classnames';
import DesktopContainer from "../../container/DesktopContainer";


const Transparency = () => (
    <Segment className={styles.segment}>
        <DesktopContainer>
            <Rail position='right' className={styles.rail}>
                <Segment raised className={styles.menuSegment}>
                    <Menu secondary vertical className={styles.menu}>
                        <Link to="/stiftung/">
                            <Menu.Item className={styles.menuItem}>Stiftung</Menu.Item>
                        </Link>
                        <Link to="/stiftung/grundsaetze/">
                            <Menu.Item className={styles.menuItem}>Grundsätze</Menu.Item>
                        </Link>
                        <Link to="/stiftung/principles/">
                            <Menu.Item className={styles.menuItem}>Prinzipien</Menu.Item>
                        </Link>
                        <Link to="/stiftung/transparenz/">
                            <Menu.Item className={cx(styles.menuItem, styles.active)}>Transparenz</Menu.Item>
                        </Link>
                        <Link to="/stiftung/anlagerichtlinien/">
                            <Menu.Item className={styles.menuItem}>Anlagerichtlinien</Menu.Item>
                        </Link>
                    </Menu>
                </Segment>
            </Rail>
        </DesktopContainer>
        <Container className={styles.container}>
            <p>Die Deutsche Stiftung Kinderdermatologie fühlt sich verpflichtet, die Öffentlichkeit einfach, transparent und klar über ihre Struktur, ihr Engagement und die Mittelherkunft sowie ihre Verwendung zu informieren.</p>
            <p>Die Deutsche Stiftung Kinderdermatologie dient der Bündelung, Vermittlung und Artikulation einer besseren gesundheitlichen und organisatorischen Versorgung hautkranker Kinder und Jugendlichen.</p>
            <p>Unsere ethischen Normen finden Sie im Leitbild. Zu diesem gehört die Forderung nach größtmöglicher Transparenz hinsichtlich Mittelherkunft und Mittelverwendung.</p>
            <p>Wir fühlen uns verantwortlich gegenüber Ihrer Annahme, dass öffentliche Mittel ebenso wie private Geldspenden mit der Erwartung an die Deutsche Stiftung Kinderdermatologie gegeben werden, nachhaltige Resultate und Wirkungen für hautkranke Kinder und Jugendliche erzielt werden. Wir bemühen uns die Resultate und Wirkungen unserer Aktivitäten zu analysieren und zu belegen. Die erzielte Wirkung unserer gemeinnütziger Stiftungsarbeit legen wir Ihnen in einem wirkungsorienten Jahresbericht nach "Social Reporting Standard" offen. Die Projekte werden durch ein Berichtswesen dokumentiert und ausgewertet. Unser Ziel ist es, dass Geldgebende ihre Spendenentscheidung auf Grundlage der Transparenz über Ansätze, Aktivitäten und Wirkungen unserer Stiftungsarbeit treffen können.</p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                Social Reporting Standard (SRS)
            </Header>
            <p>Der <a href="http://www.social-reporting-standard.de/" className={'primary'}>Social Reporting Standard (SRS)</a> bezeichnet einheitliche Richtlinien zur Berichterstattung von Initiativen und Projekten des Nonprofit-Bereichs.</p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                Bertelsmann Stiftung
            </Header>
            <p>Als Geldgeber finden Sie weitere Informationen bei der <a href="http://www.bertelsmann-stiftung.de/" className={'primary'}>Bertelsmann Stiftung</a>. Die 1977 gegründete Bertelsmann Stiftung gibt Antworten über aktuelle gesellschaftliche Probleme aller Lebensbereiche nach den „Grundsätzen des Unternehmertums und der Leistungsgerechtigkeit“ und dem Leitbild „so wenig Staat wie möglich“.</p>
        </Container>

    </Segment>
);

export default Transparency;